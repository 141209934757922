/** @jsxImportSource @emotion/react */
import "../App.css";
import "react-reflex/styles.css";
import "./JobAssignmentView.css";
import "./UnassignedJobsView.css";
import {useAppContext} from "../ApplicationContext";
import {
  Manifest,
  ManifestAttributeFilter,
  ManifestFilter,
  SearchableSortDirection,
  useAssignJobsToManifestMutation,
  useSetUserPrefMutation
} from "../generated/graphql";
import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import {AuthContext} from "../components/AuthProvider";
import React, {Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState} from "react";
import {Drawer, Position} from "@blueprintjs/core";
import {Fill} from "react-spaces";
import {LoadingSpinner} from "../components/common/LoadingSpinner";
import {UserClaims} from "@okta/okta-auth-js";
import {Constants, UserPreferences} from "../components/common/Constants";
import {useReducerAsync} from "use-reducer-async";
import AssignmentSubscription from "../components/common/AssignmentSubscription";
import DispatchGroupDataProvider from "../components/common/DispatchGroupDataProvider";
import ErrorBoundary from "../components/common/ErrorBoundary";
import JobAssignmentReducer, {
  asyncActionHandlers,
  JobAssignmentActions,
  JobAssignmentAsyncActions,
  JobAssignmentState
} from "../components/common/JobAssignmentReducer";
import JobAssignmentViewReducer, {
  FeatureFlag,
  JobAssignmentViewState,
  JobViewActionTypes,
  ManifestFilterTypePayload
} from "./JobAssignmentViewReducer";
import JobPanel, {AgJob} from "../components/job/JobPanel";
import ManifestDataProvider from "../components/manifest/ManifestDataProvider";
import AddressResolverProvider from "../components/common/AddressResolverProvider";
import {JobAssignment, JobAssignmentViewContext, CardType} from "./JobAssignmentView";
import {MapVisibilityProvider} from "../components/map/MapVisibilityContext";
import ManifestDetailsChangeEventProvider from "../components/manifest/details/ManifestDetailsChangeEventProvider";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import _, {debounce} from "lodash";
import DispatchRulesDataProvider from "../components/common/DispatchRulesDataProvider";

import {JobTypes} from "../components/settings/ColorizedIndicators/types/jobTypeSelectorValues";
import {createJsonPref, extractJsonPref, extractSimplePref, PreferenceContext} from "../providers/PreferenceProvider";
import {makeQueryStringFilter} from "../utils/QueryUtils";
import {ManifestFilterState, ManifestFilterType} from "../components/manifest/ManifestFilterState";
import AssignmentMapV2 from "../components/map/AssignmentMapV2";
import {DispatchStationDataContext} from "../components/common/DispatchStationDataProvider";
import {FeatureFlagContext} from "../providers/FeatureFlagProvider";
import ListActionBar from "../components/common/ListActionBar";
import {useListActionBarDispatch} from "../components/common/ListActionBarProvider";
import {DateRange} from "@blueprintjs/datetime";
import {AgGridReact} from "@ag-grid-community/react";
import DispatchMessaging from "../components/messaging/DispatchMessaging";
import {NewWindowPortalCommon} from "../components/common/NewWindowPortalCommon";
import ManifestDetailsContainer from "../components/manifest/details/ManifestDetailsContainer";
import {isSmallCard} from "../utils/General";
import {StatusType} from "../components/manifest/manifest-filter/ManifestFilterDatePicker";
import ManifestDriverPanel from "../components/manifest/ManifestDriverPanel";

// initial and constant of width
const minimizeButtonPixelSize = 30;

const initialState: JobAssignmentViewState = {
  manifestSort: {
    property: "driver.name.keyword",
    sortDirection: SearchableSortDirection.Asc
  },
  jobPanelSize: Constants.JOBS_PANE_DEFAULT_SIZE,
  manifestPanelSize: Constants.MANIFEST_PANE_DEFAULT_SIZE,
  manifestViewType: CardType.Compact,
  manifestDetailsState: {
    manifestDriverId: undefined,
    driverId: undefined,
    isDrawerOpen: false
  },
  manifestFilters: new ManifestFilterState(),
  manifestQuery: undefined,
  selectedCreateManifests: [],
  featureFlag: {} as FeatureFlag,
  dragAndDropTargets: [],
  currentDragAndDropTargets: [],
  showingJobIds: [],
  totalJobIds: 0,
  unassignedJobsPage: 1,
  assignmentTotalJobsNumbers: [],
  totalJobsNumberAndJobsIdOnPages: [],
  sortByColor: {
    enable: false,
    direction: SearchableSortDirection.Asc
  }
};

const assignmentInitialState: JobAssignmentState = {
  originManifest: undefined,
  selectedJobs: [],
  selectedStops: [],
  pendingActions: [],
  selectedManifest: undefined,
  disabledManifestIds: [],
  pollingOptions: {
    initialized: false,
    startPolling: () => {
      /*empty*/
    },
    stopPolling: () => {
      /*empty*/
    },
    pollingInterval: 15000
  },
  highlightManifests: []
} as JobAssignmentState;
const SPLITTER_WIDTH = 1;
enum MAP_PORTAL_ALERT {
  WHEN_OPEN = "The popped out map will work as long as the Dispatch tab that contains the job & driver board remains the primary tab on that browser window. Updates to the map will be paused if you select a different tab on that browser’s window, which removes the Dispatch tab from view.",
  WHEN_PAUSE = "Map functionality is paused when the primary dispatch tab is not the selected tab on the browser’s window. Or, if the map window is put to full screen mode covering the primary dispatch tab. Please adjust and it will work again."
}

export const buildManifestStatusByStatusType = (statusTypes: StatusType[]): ManifestFilter => {
  const result = {
    manifest: {
      and: [] as ManifestAttributeFilter[]
    }
  } as ManifestFilter;
  if (statusTypes.length > 0) {
    result.manifest?.and?.push({
      or: statusTypes.map((status) => {
        return {manifestStatus: {match: status.charAt(0)}};
      })
    });
  } else {
    result.manifest?.and?.push({
      or: [
        {manifestStatus: {match: "A"}},
        {manifestStatus: {match: "Q"}},
        {manifestStatus: {match: "I"}},
        {manifestStatus: {match: "F"}}
      ]
    });
  }
  return result;
};

const UnassignedJobsView = () => {
  const {user} = useContext(AuthContext);
  const {compactCard: compactCardEnalbled} = useContext(FeatureFlagContext);
  const [assignmentViewState, assignmentViewStateDispatch] = useReducer(JobAssignmentViewReducer, initialState);
  const [assignmentState, assignmentStateDispatch] = useReducerAsync<
    Reducer<JobAssignmentState, JobAssignmentActions>,
    JobAssignmentAsyncActions
  >(JobAssignmentReducer, assignmentInitialState, asyncActionHandlers);
  const [detailsAssignmentState, detailsAssignmentStateDispatch] = useReducerAsync<
    Reducer<JobAssignmentState, JobAssignmentActions>,
    JobAssignmentAsyncActions
  >(JobAssignmentReducer, assignmentInitialState, asyncActionHandlers);
  const [assignJobsToManifest] = useAssignJobsToManifestMutation();
  const [setUserPref] = useSetUserPrefMutation();
  const {appState} = useAppContext();
  const [minimumJobPanelWidth, setMinimumJobPanelWidth] = useState<number>(5);
  const [minimumMapPanelWidth, setMinimumMapPanelWidth] = useState<number>(5);
  const [mapWidth, setMapWidth] = useState<number>(0);
  const [mapMinimized, setMapMinimized] = useState<boolean>(false);
  const [jobWidth, setJobWidth] = useState<number>(0);
  const [jobMinimized, setJobMinimized] = useState<boolean>(false);
  const dispatchStationState = useContext(DispatchStationDataContext);
  const [initRender, setInitRender] = useState<boolean>(true);
  const {dispatchStationUI: dispatchStationsEnabled, driverMessaging: messagingEnabled} =
    useContext(FeatureFlagContext);
  const {userPreferences, userPrefsQueryRefetch} = useContext(PreferenceContext);
  const listActionToolbarDispatch = useListActionBarDispatch();
  const [statusTypes, setStatusTypes] = useState<StatusType[]>([]);

  const handleManifestViewTypeChange = useCallback(
    (viewType: CardType) => {
      assignmentViewStateDispatch({type: JobViewActionTypes.SET_MANIFEST_VIEW_TYPE, payload: viewType});
      setUserPref({
        variables: {
          name: UserPreferences.manifestViewType,
          input: {
            value: JSON.stringify({
              value: viewType
            })
          }
        }
      })
        .then(() => {
          userPrefsQueryRefetch?.();
        })
        .catch((error: Error) => {
          console.error(error);
        });
    },
    [assignmentViewStateDispatch, setUserPref, userPrefsQueryRefetch]
  );

  const handleManifestDetailsDrawerOpen = useCallback((manifest: Manifest) => {
    const manifestDriverId = manifest?.manifestDriverId;
    assignmentViewStateDispatch({
      type: JobViewActionTypes.SET_MANIFEST_DETAILS_DRAWER_OPEN,
      payload: {
        manifestDriverId: manifestDriverId,
        driverId: manifest?.driver.driverId
      }
    });
  }, []);

  const handleManifestDetailsDrawerClose = useCallback(() => {
    detailsAssignmentStateDispatch({type: "ClearAllDetailState"});
    assignmentStateDispatch({type: "ClearAllDetailState"});
    listActionToolbarDispatch({type: "SET_IS_SHOWING", payload: false});
    assignmentViewStateDispatch({
      type: JobViewActionTypes.CLEAR_MANIFEST_DETAILS_STATE
    });
  }, [assignmentStateDispatch, detailsAssignmentStateDispatch, listActionToolbarDispatch]);

  const buildManifestFilter = useCallback((): ManifestFilter => {
    const result = buildManifestStatusByStatusType(statusTypes);

    if (dispatchStationsEnabled && dispatchStationState.hasSelectedDispatchStations()) {
      result.manifest?.and?.push({
        or: Array.from(dispatchStationState.selectedDispatchStations.values()).flatMap((ds) => {
          return Array.from(ds.driverGroups).map((dg) => {
            return {
              dispatchGroup_dispatchGroupId: {eq: dg}
            } as ManifestAttributeFilter;
          });
        })
      });
    }
    console.debug("ManifestFilterState: ", assignmentViewState.manifestFilters);
    assignmentViewState.manifestFilters?.appendToFilter(result);

    if (assignmentViewState.manifestQuery) {
      result.queryString = makeQueryStringFilter(assignmentViewState.manifestQuery, [
        "driver.name",
        "driver.code",
        "manifestDriverId",
        "stops.job.jobNumber",
        "stops.job.routeNumber"
      ]);
    }

    console.debug("ManifestFilter: ", result);
    return result;
  }, [
    assignmentViewState.manifestFilters,
    assignmentViewState.manifestQuery,
    dispatchStationState,
    dispatchStationsEnabled,
    statusTypes
  ]);

  const persistUserPref = useCallback(
    async (key: string, value: any) => {
      await setUserPref({
        variables: {
          name: key,
          input: createJsonPref(value)
        }
      })
        .then(() => {
          userPrefsQueryRefetch?.();
        })
        .catch((error: Error) => {
          console.error(error);
        });
    },
    [setUserPref, userPrefsQueryRefetch]
  );

  const manifestViewType: CardType = useMemo(() => {
    return assignmentViewState?.manifestViewType;
  }, [assignmentViewState?.manifestViewType]);

  const minimumManifestWidth = useMemo(
    () => (isSmallCard(manifestViewType, compactCardEnalbled) ? 522 : 357),
    [compactCardEnalbled, manifestViewType]
  );
  useEffect(() => {
    if (initRender && userPreferences.length > 0) {
      setMapMinimized("true" === extractSimplePref(userPreferences, UserPreferences.mapPanelMinimized, "false").value);
      setJobMinimized("true" === extractSimplePref(userPreferences, UserPreferences.jobPanelMinimized, "false").value);

      const jw = (totalWidth() - minimumManifestWidth - SPLITTER_WIDTH * 2) / 2;
      setJobWidth(extractSimplePref(userPreferences, UserPreferences.jobPanelSize, jw).value as number);

      setMapWidth(
        extractSimplePref(userPreferences, UserPreferences.mapPanelSize, totalWidth() - jw - minimumManifestWidth)
          .value as number
      );
      const savedCardType = extractSimplePref(userPreferences, UserPreferences.manifestViewType).value;
      if (savedCardType)
        assignmentViewStateDispatch({
          type: JobViewActionTypes.SET_MANIFEST_VIEW_TYPE,
          payload: Number(savedCardType)
        });
      setInitRender(false);
    }
  }, [minimumManifestWidth, userPreferences, initRender]);

  // expand manifest pane when select compact card mode
  useEffect(() => {
    const currentJobWidth = jobMinimized ? 8 : jobWidth;
    const currentMapWidth = mapMinimized ? 8 : mapWidth;

    const manifestWidth = totalWidth() - currentJobWidth - currentMapWidth - SPLITTER_WIDTH * 2;
    const widthToExpand = minimumManifestWidth - manifestWidth;

    if (widthToExpand > 0) {
      if (currentJobWidth - widthToExpand / 2 < 0) {
        setMapWidth((prev) => prev - widthToExpand);
        return;
      }
      if (currentMapWidth - widthToExpand / 2 < 0) {
        setJobWidth((prev) => prev - widthToExpand);
        return;
      }
      setMapWidth((prev) => prev - widthToExpand / 2);
      setJobWidth((prev) => prev - widthToExpand / 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumManifestWidth]);

  //Handle when click minimize map button
  const handleMinimizeMap = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setMinimumMapPanelWidth(0);
    setMapMinimized(true);
    persistUserPref(UserPreferences.mapPanelMinimized, "true");
  };

  //Handle when click maximize map button
  const handleMaximizeMap = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setMinimumMapPanelWidth(5);
    setMapMinimized(false);
    persistUserPref(UserPreferences.mapPanelMinimized, "false");
  };

  //Handle when click minimize job button
  const handleMinimizeJob = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setMinimumJobPanelWidth(0);
    setJobMinimized(true);
    persistUserPref(UserPreferences.jobPanelMinimized, "true");
  };

  //Handle when click maximize job button
  const handleMaximizeJob = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setMinimumJobPanelWidth(5);
    setJobMinimized(false);
    persistUserPref(UserPreferences.jobPanelMinimized, "false");
  };

  //Handle when resizing map panel
  const handleMapPanelResize = useCallback(
    (e: any) => {
      const currentWidth = e?.domElement?.clientWidth + SPLITTER_WIDTH;
      setMapWidth(currentWidth);
      setUserPref({
        variables: {
          name: UserPreferences.mapPanelSize,
          input: createJsonPref(currentWidth)
        }
      })
        .then(() => {
          userPrefsQueryRefetch?.();
        })
        .catch((error: Error) => {
          console.error(error);
        });
    },
    [setUserPref, userPrefsQueryRefetch]
  );

  //Handle when resizing job panel
  const handleJobPaneResize = useCallback(
    (e: any) => {
      const currentWidth = e?.domElement?.clientWidth + SPLITTER_WIDTH;
      setJobWidth(currentWidth);
      setUserPref({
        variables: {
          name: UserPreferences.jobPanelSize,
          input: createJsonPref(currentWidth)
        }
      })
        .then(() => {
          userPrefsQueryRefetch?.();
        })
        .catch((error: Error) => {
          console.error(error);
        });
    },
    [setUserPref, userPrefsQueryRefetch]
  );

  const totalWidth = () => {
    return window.innerWidth;
  };

  //Calculate Job and Map flex
  const mapFlex = useMemo(() => {
    if (mapMinimized) {
      return minimumMapPanelWidth / totalWidth();
    } else {
      if (jobMinimized) {
        return (mapWidth + minimumJobPanelWidth) / totalWidth();
      }
      return +(mapWidth / totalWidth());
    }
  }, [jobMinimized, mapMinimized, mapWidth, minimumJobPanelWidth, minimumMapPanelWidth]);

  const jobFlex = useMemo(() => {
    if (jobMinimized) {
      return minimumJobPanelWidth / totalWidth();
    } else if (mapMinimized) {
      return (jobWidth + minimumMapPanelWidth) / totalWidth();
    } else {
      return jobWidth / totalWidth();
    }
  }, [jobMinimized, jobWidth, mapMinimized, minimumJobPanelWidth, minimumMapPanelWidth]);

  const onManifestFilterSelected = useCallback(
    (filterState: ManifestFilterTypePayload[], oldFilterState?: ManifestFilterTypePayload[]) => {
      if (oldFilterState && oldFilterState.length > 0) {
        assignmentViewStateDispatch({
          type: JobViewActionTypes.CLEAR_MANIFEST_FILTER,
          payload: oldFilterState.map((filter) => filter.filterType)
        });
      }
      if (filterState.length > 0) {
        assignmentViewStateDispatch({
          type: JobViewActionTypes.ADD_MANIFEST_FILTER,
          payload: filterState
        });
      } else {
        assignmentViewStateDispatch({
          type: JobViewActionTypes.ADD_MANIFEST_FILTER,
          payload: {
            filterType: ManifestFilterType.NONE,
            value: undefined
          }
        });
      }
    },
    []
  );

  const onManifestFilterDateChanged = useCallback(
    (dateRange: DateRange | undefined, selectedDateRange: DateRange | undefined) => {
      if (dateRange !== selectedDateRange) {
        setUserPref({
          variables: {
            name: UserPreferences.manifestDateRangeHeader,
            input: createJsonPref(dateRange, true)
          }
        }).then(() => {
          userPrefsQueryRefetch?.();
        });
        assignmentViewStateDispatch({
          type: JobViewActionTypes.REPLACE_MANIFEST_FILTER,
          payload: {filterType: ManifestFilterType.DATE_RANGE, value: dateRange}
        });
      }
    },
    [setUserPref, userPrefsQueryRefetch]
  );

  const updateDriverGroupPref = useCallback(
    (arrayOfManifestFilter: React.MutableRefObject<number[]>) => {
      const driverGroupsSelected = extractJsonPref(userPreferences, UserPreferences.manifestGroupHeader, true)?.value;
      if (!_.isEqual(driverGroupsSelected, arrayOfManifestFilter.current)) {
        setUserPref({
          variables: {
            name: UserPreferences.manifestGroupHeader,
            input: createJsonPref(arrayOfManifestFilter.current, true)
          }
        }).then(() => {
          if (userPrefsQueryRefetch) userPrefsQueryRefetch();
        });
      }
    },
    [setUserPref, userPreferences, userPrefsQueryRefetch]
  );

  const onSelectDriverGroup = useCallback(
    (key: number, arrayOfManifestFilter: React.MutableRefObject<number[]>) => {
      updateDriverGroupPref(arrayOfManifestFilter);
      assignmentViewStateDispatch({
        type: JobViewActionTypes.ADD_MANIFEST_FILTER,
        payload: {filterType: ManifestFilterType.DRIVER_GROUP, value: key}
      });
    },
    [updateDriverGroupPref]
  );

  const onUnselectDriverGroup = useCallback(
    (key: number, arrayOfManifestFilter: React.MutableRefObject<number[]>) => {
      updateDriverGroupPref(arrayOfManifestFilter);
      assignmentViewStateDispatch({
        type: JobViewActionTypes.REMOVE_MANIFEST_FILTER,
        payload: {filterType: ManifestFilterType.DRIVER_GROUP, value: key}
      });
    },
    [updateDriverGroupPref]
  );

  const onUnselectAllDriverGroup = useCallback(
    (arrayOfManifestFilter: React.MutableRefObject<number[]>) => {
      updateDriverGroupPref(arrayOfManifestFilter);
      assignmentViewStateDispatch({
        type: JobViewActionTypes.CLEAR_MANIFEST_FILTER,
        payload: ManifestFilterType.DRIVER_GROUP
      });
    },
    [updateDriverGroupPref]
  );

  const onhandleManifestQuery = useCallback((q: string) => {
    assignmentViewStateDispatch({
      type: JobViewActionTypes.SET_MANIFEST_QUERY,
      payload: q
    });
  }, []);

  const gridRef = useRef<AgGridReact<AgJob>>();

  const getGridRef = useCallback((ref: AgGridReact<AgJob>) => {
    gridRef.current = ref;
  }, []);

  return (
    <LoadingSpinner loading={!appState.loaded}>
      <Fill data-testid="jobAssignmentView">
        <DispatchGroupDataProvider>
          <>{messagingEnabled && <DispatchMessaging driverId={assignmentViewState.manifestDetailsState.driverId} />}</>
          <AssignmentSubscription dispatch={assignmentStateDispatch} userClaims={user as UserClaims} />
          <JobAssignment.Provider
            value={{
              updateState: assignmentStateDispatch,
              assignJobsToManifestMutator: assignJobsToManifest,
              pendingActions: assignmentState.pendingActions,
              highlightManifests: assignmentState.highlightManifests
            }}
          >
            <JobAssignmentViewContext.Provider
              value={{
                updateState: assignmentViewStateDispatch
              }}
            >
              <MapVisibilityProvider>
                <ManifestDataProvider
                  filter={buildManifestFilter()}
                  sort={{
                    field: assignmentViewState.manifestSort.property,
                    direction: assignmentViewState.manifestSort.sortDirection
                  }}
                >
                  <AddressResolverProvider>
                    <ReflexContainer orientation="vertical">
                      <ReflexElement
                        minSize={minimumJobPanelWidth}
                        flex={jobFlex}
                        onStopResize={debounce(handleJobPaneResize, 150)}
                        style={{overflow: "visible"}}
                      >
                        <DispatchRulesDataProvider jobType={JobTypes.UnassignedJobs}>
                          <JobPanel
                            dragAndDropTargets={assignmentViewState.dragAndDropTargets}
                            currentDragAndDropTargets={assignmentViewState.currentDragAndDropTargets}
                            assignmentDispatch={assignmentStateDispatch}
                            assignmentViewDispatch={assignmentViewStateDispatch}
                            assignmentState={assignmentState}
                            assignmentViewState={assignmentViewState}
                            getGridRef={getGridRef}
                          />
                        </DispatchRulesDataProvider>
                        <MinimizeButton
                          className={"map-button"}
                          data-testid={jobMinimized ? "restore-job-button" : "reduce-job-button"}
                          onClick={jobMinimized ? handleMaximizeJob : handleMinimizeJob}
                          title={jobMinimized ? "Restore Job Area" : "Reduce Job Area"}
                          pixelOffset={jobMinimized ? 8 : 0}
                          side="right"
                        >
                          <MinimizeIcon
                            icon={jobMinimized ? solid("chevron-circle-right") : solid("chevron-circle-left")}
                          />
                        </MinimizeButton>
                      </ReflexElement>
                      <ReflexSplitter propagate={false} style={{pointerEvents: jobMinimized ? "none" : "auto"}}>
                        <div className="spaces-resize-handle resize-left" />
                      </ReflexSplitter>
                      <ReflexElement
                        flex={appState.isMapPortalOpen ? 1 - jobFlex : undefined}
                        minSize={minimumManifestWidth}
                        style={{minWidth: `${minimumManifestWidth}px`}}
                      >
                        <ManifestDriverPanel
                          setStatusTypes={setStatusTypes}
                          statusTypes={statusTypes}
                          dispatch={assignmentViewStateDispatch}
                          assignmentDispatch={assignmentStateDispatch}
                          state={{
                            ...assignmentViewState,
                            manifestViewType: manifestViewType
                          }}
                          assignmentState={assignmentState}
                          onChangedView={handleManifestViewTypeChange}
                          onManifestDetailsDrawerOpen={handleManifestDetailsDrawerOpen}
                          filterSelected={onManifestFilterSelected}
                          filterDateChanged={onManifestFilterDateChanged}
                          selectDriverGroup={onSelectDriverGroup}
                          unselectDriverGroup={onUnselectDriverGroup}
                          unselectAllDriverGroup={onUnselectAllDriverGroup}
                          handleManifestQuery={onhandleManifestQuery}
                        />
                      </ReflexElement>
                      {appState.isMapPortalOpen && (
                        <NewWindowPortalCommon
                          target="map"
                          title="Dispatch Map"
                          alertTextWhenOpen={MAP_PORTAL_ALERT.WHEN_OPEN}
                          alertTextWhenPaused={MAP_PORTAL_ALERT.WHEN_PAUSE}
                        >
                          <AssignmentMapV2
                            gridRef={gridRef}
                            assignmentState={assignmentState}
                            assignmentDispatch={assignmentStateDispatch}
                            assignmentViewState={assignmentViewState}
                            mapResponsiveWidth={mapWidth}
                            onManifestDetailsDrawerOpen={handleManifestDetailsDrawerOpen}
                          />
                        </NewWindowPortalCommon>
                      )}
                      <ReflexSplitter propagate={false} style={{pointerEvents: mapMinimized ? "none" : "auto"}}>
                        <div className="spaces-resize-handle resize-left" />
                      </ReflexSplitter>
                      {!appState.isMapPortalOpen && (
                        <ReflexElement
                          flex={mapFlex}
                          onStopResize={debounce(handleMapPanelResize, 150)}
                          minSize={minimumMapPanelWidth}
                          style={{overflow: "visible"}}
                        >
                          <MinimizeButton
                            className={"map-button"}
                            data-testid={mapMinimized ? "restore-map-button" : "reduce-map-button"}
                            onClick={mapMinimized ? handleMaximizeMap : handleMinimizeMap}
                            title={mapMinimized ? "Restore Map Area" : "Reduce Map Area"}
                            pixelOffset={mapMinimized ? 8 : 0}
                            side="left"
                          >
                            <MinimizeIcon
                              icon={mapMinimized ? solid("chevron-circle-left") : solid("chevron-circle-right")}
                            />
                          </MinimizeButton>
                          <AssignmentMapV2
                            gridRef={gridRef}
                            assignmentState={assignmentState}
                            assignmentDispatch={assignmentStateDispatch}
                            assignmentViewState={assignmentViewState}
                            mapResponsiveWidth={mapWidth}
                            onManifestDetailsDrawerOpen={handleManifestDetailsDrawerOpen}
                          />
                        </ReflexElement>
                      )}
                    </ReflexContainer>
                  </AddressResolverProvider>
                </ManifestDataProvider>
              </MapVisibilityProvider>
              <JobAssignment.Provider
                value={{
                  updateState: detailsAssignmentStateDispatch,
                  assignJobsToManifestMutator: assignJobsToManifest,
                  pendingActions: detailsAssignmentState.pendingActions,
                  highlightManifests: detailsAssignmentState.highlightManifests
                }}
              >
                <Drawer
                  className="manifest_drawer_container"
                  isOpen={assignmentViewState.manifestDetailsState.isDrawerOpen}
                  onClose={handleManifestDetailsDrawerClose}
                  canEscapeKeyClose
                  enforceFocus={false}
                  canOutsideClickClose={false}
                  hasBackdrop={false}
                  size="100%"
                  position={Position.LEFT}
                  portalClassName="manifest_drawer_container_portal"
                >
                  <ErrorBoundary>
                    {assignmentViewState.manifestDetailsState.manifestDriverId && (
                      <ManifestDetailsChangeEventProvider
                        manifestDriverId={assignmentViewState.manifestDetailsState.manifestDriverId}
                      >
                        <DispatchRulesDataProvider jobType={JobTypes.AssignedStops}>
                          <MapVisibilityProvider>
                            <ManifestDetailsContainer
                              manifestDriverId={assignmentViewState.manifestDetailsState.manifestDriverId}
                              onClose={handleManifestDetailsDrawerClose}
                              assignmentViewState={assignmentViewState}
                              assignmentViewStateDispatch={assignmentViewStateDispatch}
                              jobAssignmentState={detailsAssignmentState}
                              jobAssignmentDispatch={detailsAssignmentStateDispatch}
                              onChangedView={handleManifestViewTypeChange}
                            />
                            <ListActionBar />
                          </MapVisibilityProvider>
                        </DispatchRulesDataProvider>
                      </ManifestDetailsChangeEventProvider>
                    )}
                  </ErrorBoundary>
                </Drawer>
              </JobAssignment.Provider>
            </JobAssignmentViewContext.Provider>
          </JobAssignment.Provider>
        </DispatchGroupDataProvider>
      </Fill>
    </LoadingSpinner>
  );
};
export default UnassignedJobsView;

const MinimizeIcon = styled(FontAwesomeIcon)`
  width: ${minimizeButtonPixelSize * 0.75}px;
  height: ${minimizeButtonPixelSize * 0.75}px;
  border-radius: 50%;
  color: #14305a;
  background-color: darkgray;

  &:active,
  &:hover {
    background-color: #ffffff;
  }

  transition: background-color 0.5s;
`;

type minimizeButtonProps = {
  pixelOffset?: number;
  side: "left" | "right";
};

const MinimizeButton = styled.div<minimizeButtonProps>`
  position: absolute;
  top: 50%;
  left: ${(props) =>
    props.side === "left" ? ({pixelOffset = -2}) => `${-pixelOffset - minimizeButtonPixelSize / 2}px` : ""};
  right: ${(props) =>
    props.side === "right" ? ({pixelOffset = -2}) => `${-pixelOffset - minimizeButtonPixelSize / 2}px` : ""};
  cursor: pointer;
  width: ${minimizeButtonPixelSize}px;
  height: ${minimizeButtonPixelSize}px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;

  background-color: #14305a;
  overflow: hidden;

  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);

  transform: translateY(-2px);

  ${"&:active&:hover" /* prevent intellij format bug from inserting space between &:active and &:hover */} {
    transform: translateY(0);
  }
`;
