/** @jsxImportSource @emotion/react */
import {Button, Dialog, Icon, Text} from "@blueprintjs/core";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import * as React from "react";
import {Dispatch, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import ManifestMenu from "./ManifestMenu";
import ManifestViewSelector from "./ManifestViewSelector";
import {
  Driver,
  DriverFilterInput,
  Manifest,
  SearchableDriverSortableFields,
  SearchableSortDirection,
  useCreateManifestMutation,
  useSearchDriversEnhancedQuery,
  useSearchVehicleTypesQuery,
  useSetUserPrefMutation,
  VehicleType
} from "../../generated/graphql";
import {CardType} from "../../views/JobAssignmentView";
import {
  JobAssignmentViewState,
  JobViewActions,
  JobViewActionTypes,
  ManifestFilterTypePayload
} from "../../views/JobAssignmentViewReducer";
import {Constants, DateFormats, ERR_WARNING_MESSAGES, UserPreferences} from "../common/Constants";
import {JobAssignmentActions, JobAssignmentAsyncActions, JobAssignmentState} from "../common/JobAssignmentReducer";
import MultiSelectDropDown, {MultiSelectDropDownItem, StyledButton} from "../common/MultiSelectDropDown";
import {ManifestDataContext, ManifestDataState} from "./ManifestDataProvider";
import {Box, IconButton, TablePagination} from "@mui/material";
import {LabelDisplayedRowsArgs} from "@mui/material/TablePagination/TablePagination";
import "./ManifestDriverPanel.css";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import CreateManifestModal, {DriverItem} from "./create-manifest/CreateManifestModal";
import {getArrayFromField} from "../../utils/General";
import SearchBar from "../common/SearchBar";
import {ManifestFilterType} from "./ManifestFilterState";
import ManifestFilterDialog from "./manifest-filter/ManifestFilterDialog";
import {format} from "date-fns";
import {DateRange} from "@blueprintjs/datetime";
import {DispatchGroupDataContext, DispatchGroupDataState} from "../common/DispatchGroupDataProvider";
import {createJsonPref, extractJsonPref, PreferenceContext} from "../../providers/PreferenceProvider";
import {isArray, isEqual} from "lodash";
import ManifestCardConfiguration from "./card-configuration/ManifestCardConfiguration";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";
import {useMapVisibilityContext} from "../map/MapVisibilityContext";
import {ManifestWarningMessage} from "./ManifestWarningMessage";
import {DispatchStationDataContext} from "../common/DispatchStationDataProvider";
import {DateSelection, StatusType} from "./manifest-filter/ManifestFilterDatePicker";
import {StopStatusCategory} from "./types/StopStatusManifestFilter";
import {WarningIcon} from "./StatusIconV3";

const ManifestDriverViewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #ededed;
  z-index: 1;
  box-shadow: 2px 0px 12px 0px #00000026 inset;
`;

const ToolbarContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
`;

const LeftToolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const RightToolbar = styled.div<{isNarrowed?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${(props) => (props.isNarrowed ? "0" : "5px")};
`;

const AddButton = styled(Button)`
  width: 35px;
  height: 30px;
  background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px #00000040;

  .bp4-icon {
    color: #ffffff;
  }
`;

const PagingMenuContainer = styled.div({
  marginTop: "auto",
  display: "flex",
  justifyContent: "flex-end",
  height: "auto"
});

const MuiPagingMenu = styled(TablePagination)`
  height: auto;
  margin-right: 18px;
  font-size: 13px;
`;

const CreateManifestDialog = styled(Dialog)`
  width: fit-content;
  height: fit-content;
`;

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onPageChange(event as React.MouseEvent<HTMLButtonElement>, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5, fontSize: "13px"}}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon fontSize={"small"} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      Page <b>{page + 1}</b> of <b>{Math.max(1, Math.ceil(count / rowsPerPage))}</b>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon fontSize={"small"} />
      </IconButton>
    </Box>
  );
}

enum ManifestWarningMessageTypes {
  SORT_WARNING = "Color sorting is limited to the current page",
  FILTER_WARNING = "Color filtering is limited to the current page",
  SORT_AND_FILTER_WARNING = "Color filtering/sorting is limited to the current page"
}
export type SelectedFilterState = {
  filterType: ManifestFilterType;
  filterValue: string | number | undefined;
};
export type ManifestFilterPreference = {
  statusTypes?: StatusType[];
  dateSelectionText?: string;
  emptyActive?: boolean;
  newActive?: boolean;
};
type ManifestDriverProps = {
  dispatch: Dispatch<JobViewActions>;
  assignmentDispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  state: JobAssignmentViewState;
  assignmentState: JobAssignmentState;
  driverGroupFilters?: Set<number>;
  onChangedView(type: CardType): void;
  onManifestDetailsDrawerOpen(manifest: Manifest, isOpen: boolean): void;
  hideCreateManifestButton?: boolean;
  filterSelected(filterState: ManifestFilterTypePayload[], oldFilterState?: ManifestFilterTypePayload[]): void;
  filterDateChanged(dateRange: DateRange | undefined, selectedDateRange: DateRange | undefined): void;
  selectDriverGroup(key: number, arrayOfManifestFilter: React.MutableRefObject<number[]>): void;
  unselectDriverGroup(key: number, arrayOfManifestFilter: React.MutableRefObject<number[]>): void;
  unselectAllDriverGroup(arrayOfManifestFilter: React.MutableRefObject<number[]>): void;
  handleManifestQuery(query: string): void;
  disabledManifestIds?: number[];
  setStatusTypes: React.Dispatch<React.SetStateAction<StatusType[]>>;
  statusTypes: StatusType[];
};
const fetchDriverLimit = 100;

const FilterDefaultText = "Filters";

const ManifestDriverPanel = ({
  dispatch,
  state,
  assignmentState,
  onChangedView,
  onManifestDetailsDrawerOpen,
  hideCreateManifestButton = false,
  filterSelected,
  filterDateChanged,
  selectDriverGroup,
  unselectDriverGroup,
  unselectAllDriverGroup,
  handleManifestQuery,
  disabledManifestIds,
  setStatusTypes,
  statusTypes
}: ManifestDriverProps) => {
  const [isOpenManifestModal, setIsOpenManifestModal] = useState<boolean>(false);
  const [isFilterManifestModalOpen, setFilterManifestModalOpen] = useState<boolean>(false);
  const viewType = state.manifestViewType;
  const selectedManifest = assignmentState.selectedManifest;
  const [createManifest] = useCreateManifestMutation();
  const [filterButtonLabel, setFilterButtonLabel] = useState<string>(FilterDefaultText);
  const [selectedFilters, setSelectedFilters] = useState<ManifestFilterTypePayload[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>();
  const [searchExpanded, setSearchExpanded] = useState<boolean>(false);
  const [dateSelectionText, setDateSelectionText] = useState<string>(DateSelection.All_DAY);
  const [emptyActive, setEmptyActive] = useState<boolean>(false);
  const [newActive, setNewActive] = useState<boolean>(false);
  const {dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const dispatchStationState = useContext(DispatchStationDataContext);
  const manifestDataState = useContext<ManifestDataState>(ManifestDataContext);

  const arrayOfManifestFilter = useRef<number[]>([]);
  const dispatchGroupData = useContext<DispatchGroupDataState>(DispatchGroupDataContext);
  const {cardConfig: cardConfigEnable, colorSort: colorSortEnable, manifestPageSizes} = useContext(FeatureFlagContext);

  const [setUserPref] = useSetUserPrefMutation();
  const {userPreferences, userPrefsQueryRefetch} = useContext(PreferenceContext);
  const [initializing, setInitializing] = useState<boolean>(true);
  const [driverItems, setDriverItems] = useState<Driver[]>([]);
  const [driverQuery, setDriverQuery] = useState<string>("");
  const [driverFilter, setDriverFilter] = useState<DriverFilterInput>({});

  const currentManifestFilterHeader = useMemo(
    () =>
      (extractJsonPref(userPreferences, UserPreferences.manifestFilterHeader)?.value as ManifestFilterPreference) ?? {},
    [userPreferences]
  );

  const isAssignPanel = useMemo(() => {
    return state.manifestDetailsState.isDrawerOpen;
  }, [state.manifestDetailsState.isDrawerOpen]);

  const {fetchMore, refetch} = useSearchDriversEnhancedQuery({
    variables: {
      limit: fetchDriverLimit,
      sort: [
        {
          field: SearchableDriverSortableFields.Name,
          direction: SearchableSortDirection.Asc
        }
      ],
      filter: driverFilter,
      offset: 0
    },
    onCompleted: (data) => {
      setDriverItems(data?.searchDriversEnhanced?.items as Driver[]);
    }
  });

  const filterDispatchGroupIds = useMemo(() => {
    let dipatchGroupIds = [];
    if (state.manifestFilters?.hasFilter(ManifestFilterType.DRIVER_GROUP)) {
      dipatchGroupIds = Array.from(
        state.manifestFilters.manifestFilters.get(ManifestFilterType.DRIVER_GROUP)?.value ?? []
      );
    } else if (dispatchStationState.hasSelectedDispatchStations()) {
      dipatchGroupIds = Array.from(dispatchStationState.selectedDispatchStations.values()).flatMap((ds) => {
        return Array.from(ds.driverGroups);
      });
    }
    return dipatchGroupIds.map((id) => ({dispatchGroupId: {eq: id}}));
  }, [dispatchStationState, state.manifestFilters]);

  const onLoadMore = useCallback(() => {
    const currentLength = driverItems.length;
    fetchMore({
      variables: {
        offset: currentLength
      }
    }).then((result) => {
      if (result) {
        setDriverItems((prev) => [...prev, ...(result.data?.searchDriversEnhanced?.items as Driver[])]);
      }
    });
  }, [driverItems.length, fetchMore]);

  const vehicleTypesResults = useSearchVehicleTypesQuery({
    variables: {
      limit: 1000
    }
  });

  const handleDateRangeChanged = useCallback((dateRange: DateRange | undefined) => {
    setSelectedDateRange(dateRange);
  }, []);

  useEffect(() => {
    if (driverQuery.length > 2) {
      setDriverFilter({
        driver: {
          driverStatus: {eq: "A"},
          or: filterDispatchGroupIds
        },
        multiFieldWildcard: {
          wildcard: `*${driverQuery}*`,
          caseInsensitive: true,
          fields: ["name.keyword", "driverCode.keyword"]
        }
      });
    } else {
      setDriverFilter({
        driver: {
          driverStatus: {eq: "A"},
          or: filterDispatchGroupIds
        }
      });
    }
  }, [driverQuery, filterDispatchGroupIds]);

  useEffect(() => {
    if (userPreferences.length > 0 && initializing && manifestDataState.setPageSize) {
      const manifestRowPerPage = extractJsonPref(userPreferences, UserPreferences.manifestRowPerPage)?.value;
      if (manifestRowPerPage) {
        manifestDataState.setPageSize(parseInt(manifestRowPerPage));
      }
      const manifestDateRangeHeader = extractJsonPref(userPreferences, UserPreferences.manifestDateRangeHeader)?.value;
      if (manifestDateRangeHeader && isArray(manifestDateRangeHeader) && manifestDateRangeHeader.length > 1) {
        const dateRange = manifestDateRangeHeader.map((d) => new Date(d));
        handleDateRangeChanged(dateRange as DateRange);
        dispatch({
          type: JobViewActionTypes.REPLACE_MANIFEST_FILTER,
          payload: {filterType: ManifestFilterType.DATE_RANGE, value: dateRange as DateRange}
        });
      }
      const manifestGroupHeader = extractJsonPref(userPreferences, UserPreferences.manifestGroupHeader)?.value;
      if (manifestGroupHeader && isArray(manifestGroupHeader)) {
        arrayOfManifestFilter.current = manifestGroupHeader;
        manifestGroupHeader.forEach((key) => {
          dispatch({
            type: JobViewActionTypes.ADD_MANIFEST_FILTER,
            payload: {filterType: ManifestFilterType.DRIVER_GROUP, value: key}
          });
        });
      }
      const manifestFilterHeader = extractJsonPref(userPreferences, UserPreferences.manifestFilterHeader)
        ?.value as ManifestFilterPreference;
      if (Object.keys(manifestFilterHeader ?? {}).length > 0) {
        const emptyActive = manifestFilterHeader.emptyActive ?? false;
        setEmptyActive(emptyActive);
        const newActive = manifestFilterHeader.newActive ?? false;
        setNewActive(newActive);
        if (!emptyActive && !newActive) {
          setSelectedFilters([]);
          filterSelected([]);
        } else {
          const filterState: ManifestFilterTypePayload[] = [];
          if (emptyActive) {
            filterState.push({filterType: ManifestFilterType.STOP_STATUS, value: StopStatusCategory.COMPLETE});
          }
          if (newActive) {
            filterState.push({filterType: ManifestFilterType.MANIFEST_AGE, value: Constants.MANIFEST_NEW_AGE});
          }
          filterSelected(filterState);
          setSelectedFilters(filterState);
        }
        const dateSelection = manifestFilterHeader.dateSelectionText ?? "";
        setDateSelectionText(dateSelection);
        const statusTypes = manifestFilterHeader.statusTypes ?? [];
        setStatusTypes(statusTypes);
      }
      const manifestSortingHeader = extractJsonPref(userPreferences, UserPreferences.manifestSortingHeader)?.value;
      if (manifestSortingHeader && Object.keys(manifestSortingHeader).length > 0) {
        dispatch({
          type: JobViewActionTypes.SET_MANIFEST_SORT,
          payload: manifestSortingHeader
        });
      }
      if (colorSortEnable) {
        const manifestCardSortByColor = extractJsonPref(
          userPreferences,
          UserPreferences.manifestCardSortByColor
        )?.value;
        if (manifestCardSortByColor !== undefined) {
          dispatch({
            type: JobViewActionTypes.SET_SORT_BY_COLOR,
            payload: manifestCardSortByColor
          });
        }
      }
      setInitializing(false);
    }
  }, [
    userPreferences,
    initializing,
    manifestDataState,
    dispatch,
    state.manifestSort,
    colorSortEnable,
    filterSelected,
    setStatusTypes,
    isAssignPanel,
    handleDateRangeChanged,
    manifestPageSizes
  ]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<unknown> | null,
    page: number
  ) => {
    manifestDataState.setPage(page);
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (manifestDataState.setPageSize) {
      manifestDataState.setPageSize(parseInt(event.target.value));
      mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
      if (isAssignPanel) return;
      setUserPref({
        variables: {
          name: UserPreferences.manifestRowPerPage,
          input: createJsonPref(parseInt(event.target.value), true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
    }
  };
  const driverGroupItems = useMemo(() => {
    const map: Map<number, MultiSelectDropDownItem> = new Map();
    if (dispatchGroupData.driverGroups.length > 0) {
      const groups = [...dispatchGroupData.driverGroups];
      groups.sort((a, b) => a.dispatchGroup!.localeCompare(b.dispatchGroup!));
      groups.forEach((group) => {
        map.set(group.dispatchGroupId!, {
          name: group.dispatchGroup,
          selected: state.manifestFilters?.hasFilterValue(group.dispatchGroupId!, ManifestFilterType.DRIVER_GROUP), //Maybe save as user pref
          id: group.dispatchGroupId
        } as MultiSelectDropDownItem);
      });
    }
    return map;
  }, [dispatchGroupData.driverGroups, state.manifestFilters]);

  const onSelectDriverGroup = (key: number) => {
    arrayOfManifestFilter.current.push(key);
    selectDriverGroup(key, arrayOfManifestFilter);
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };

  const onUnselectDriverGroup = (key: number) => {
    arrayOfManifestFilter.current = arrayOfManifestFilter.current.filter((d) => d !== key);
    unselectDriverGroup(key, arrayOfManifestFilter);
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };

  const onUnselecAlltDriverGroup = () => {
    arrayOfManifestFilter.current = [];
    unselectAllDriverGroup(arrayOfManifestFilter);
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };

  const handleRowLabels = (paginationInfo: LabelDisplayedRowsArgs): React.ReactNode => {
    return (
      <Text style={{fontSize: "13px"}}>
        <b>{paginationInfo.from}</b> to <b>{paginationInfo.to}</b> of <b>{paginationInfo.count}</b>
      </Text>
    );
  };

  const handleCreateManifest = (date: Date) => {
    dispatch({
      type: JobViewActionTypes.CREATE_MANIFEST,
      payload: {
        drivers: state.selectedCreateManifests,
        date: format(date, DateFormats.localDate),
        mutate: createManifest
      }
    });
    setIsOpenManifestModal(false);
  };

  const openCreateManifestModal = () => {
    setIsOpenManifestModal(true);
  };

  const closeCreateManifestModal = () => {
    dispatch({
      type: JobViewActionTypes.CLEAR_SELECTED_CREATE_MANIFESTS
    });
    setIsOpenManifestModal(false);
  };

  const openFilterManifestModal = () => {
    setFilterManifestModalOpen(true);
  };

  const updateManifestFilterHeaderPref = useCallback(
    (newPref: ManifestFilterPreference) => {
      const newManifestFilterPref: ManifestFilterPreference = {
        ...currentManifestFilterHeader,
        ...newPref
      };
      if (isAssignPanel || isEqual(newManifestFilterPref, currentManifestFilterHeader)) return;
      setUserPref({
        variables: {
          name: UserPreferences.manifestFilterHeader,
          input: createJsonPref(newManifestFilterPref, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
    },
    [currentManifestFilterHeader, isAssignPanel, setUserPref, userPrefsQueryRefetch]
  );

  const closeFilterManifestModal = useCallback(() => {
    setFilterManifestModalOpen(false);
    const newFilter: ManifestFilterPreference = {
      dateSelectionText: dateSelectionText,
      emptyActive: emptyActive,
      newActive: newActive,
      statusTypes: statusTypes
    };
    updateManifestFilterHeaderPref(newFilter);
  }, [dateSelectionText, emptyActive, newActive, statusTypes, updateManifestFilterHeaderPref]);

  const handleSelect = (item: DriverItem) => {
    dispatch({type: JobViewActionTypes.SET_SELECTED_CREATE_MANIFEST, payload: item});
  };

  const handleClear = () => {
    dispatch({
      type: JobViewActionTypes.CLEAR_SELECTED_CREATE_MANIFESTS
    });
    refetch();
  };

  const onFilterSelected = (
    filterType: ManifestFilterType.NONE | ManifestFilterType.MANIFEST_AGE | ManifestFilterType.STOP_STATUS,
    filterValue: string | number | undefined
  ) => {
    const newManifestFilter = {
      filterType,
      value: filterValue
    };
    if (filterType === ManifestFilterType.NONE) {
      filterSelected([]);
      setSelectedFilters([]);
    } else {
      const updatedFilters = selectedFilters.some((x) => x.filterType === filterType)
        ? selectedFilters.filter((x) => x.filterType !== filterType)
        : [...selectedFilters, newManifestFilter];
      setSelectedFilters(updatedFilters);
      filterSelected(updatedFilters, selectedFilters);
    }
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };
  const onFilterDateChanged = (dateRange: DateRange | undefined) => {
    handleDateRangeChanged(dateRange);
    filterDateChanged(dateRange, selectedDateRange);
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };
  const clearAllFilters = useCallback(() => {
    //Empty && New
    setSelectedFilters([]);
    setEmptyActive(false);
    setNewActive(false);
    filterSelected([], selectedFilters);

    //SelectedDateRange
    handleDateRangeChanged(undefined);
    setDateSelectionText(DateSelection.All_DAY);
    filterDateChanged(undefined, selectedDateRange);

    //SelectedManifestStatusTypes
    setStatusTypes([]);

    //Save UserPref
    if (isAssignPanel) return;
    setUserPref({
      variables: {
        name: UserPreferences.manifestDateRangeHeader,
        input: createJsonPref(undefined, true)
      }
    });
    const resetFilter: ManifestFilterPreference = {
      dateSelectionText: "",
      emptyActive: false,
      newActive: false
    };
    updateManifestFilterHeaderPref(resetFilter);
  }, [
    filterSelected,
    selectedFilters,
    handleDateRangeChanged,
    filterDateChanged,
    selectedDateRange,
    setStatusTypes,
    isAssignPanel,
    setUserPref,
    updateManifestFilterHeaderPref
  ]);

  const onSearchManifest = React.useCallback(
    (query: string) => {
      mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
      handleManifestQuery(query);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showWarningMessage = useMemo(() => {
    return state.sortByColor.enable && manifestDataState.pageCount > 1;
  }, [manifestDataState.pageCount, state.sortByColor.enable]);

  const renderContentMessage = useMemo(() => {
    if (state.sortByColor) {
      return ManifestWarningMessageTypes.SORT_WARNING;
    }
    //Add more case in next ticket
    return "";
  }, [state.sortByColor]);

  useEffect(() => {
    let numberOfFilters = 0;
    if (newActive) {
      numberOfFilters++;
    }
    if (emptyActive) {
      numberOfFilters++;
    }
    if (selectedDateRange) {
      numberOfFilters++;
    }
    if (statusTypes.length > 0) {
      numberOfFilters += statusTypes.length;
    }
    setFilterButtonLabel(numberOfFilters > 0 ? `${numberOfFilters} Filters` : FilterDefaultText);
  }, [emptyActive, newActive, selectedDateRange, statusTypes]);
  return (
    <ManifestDriverViewsContainer data-testid="manifestDriverPanel">
      <ToolbarContainer>
        <LeftToolbar>
          {!hideCreateManifestButton && (
            <>
              <AddButton
                data-testid="toolbar-create-button"
                disabled={isOpenManifestModal}
                rightIcon={"plus"}
                onClick={openCreateManifestModal}
              />
              <CreateManifestDialog
                isOpen={isOpenManifestModal}
                portalClassName="create-manifest-dialog"
                onClose={closeCreateManifestModal}
              >
                <CreateManifestModal
                  onLoadMore={onLoadMore}
                  vehicleTypes={
                    getArrayFromField(vehicleTypesResults?.data?.searchVehicleTypes?.items) as VehicleType[]
                  }
                  options={driverItems}
                  selectedOptions={state.selectedCreateManifests}
                  onSearchDriver={setDriverQuery}
                  onOptionSelect={handleSelect}
                  onCreateManifest={handleCreateManifest}
                  onClear={handleClear}
                  onClose={closeCreateManifestModal}
                />
              </CreateManifestDialog>
            </>
          )}
          <Popover2
            portalClassName="filter-manifest-portal"
            popoverClassName="filter-manifest-modal"
            isOpen={isFilterManifestModalOpen}
            onClose={closeFilterManifestModal}
            content={
              <ManifestFilterDialog
                filterDateRange={selectedDateRange}
                onFilterSelect={onFilterSelected}
                onFilterDateRangeChanged={onFilterDateChanged}
                onClose={closeFilterManifestModal}
                setStatusTypes={setStatusTypes}
                statusTypes={statusTypes}
                emptyActive={emptyActive}
                newActive={newActive}
                setEmptyActive={setEmptyActive}
                setNewActive={setNewActive}
                dateSelectionText={dateSelectionText}
                setDateSelectionText={setDateSelectionText}
              />
            }
          >
            <StyledButton
              data-testid="toolbar-filter-button"
              hasValue={filterButtonLabel !== FilterDefaultText}
              text={filterButtonLabel}
              rightIcon={
                filterButtonLabel !== FilterDefaultText ? (
                  <Icon data-testid="clear-icon" icon="cross" onClick={clearAllFilters} />
                ) : (
                  "chevron-down"
                )
              }
              onClick={openFilterManifestModal}
            />
          </Popover2>
          <MultiSelectDropDown
            text={"Groups"}
            testIdPrefix={"driver-groups"}
            items={driverGroupItems}
            onSelected={onSelectDriverGroup}
            onUnselected={onUnselectDriverGroup}
            clearFilter
            onUnselectedAll={onUnselecAlltDriverGroup}
          />
        </LeftToolbar>
        <RightToolbar isNarrowed={manifestDataState.hasBadData}>
          {manifestDataState.hasBadData && (
            <Tooltip2 content={ERR_WARNING_MESSAGES.BAD_DATA} placement={"auto"}>
              <div style={{width: "15px", height: "15px"}}>
                <WarningIcon />
              </div>
            </Tooltip2>
          )}
          <SearchBar
            hasBadData={manifestDataState.hasBadData}
            idPrefix={"manifests"}
            onSearch={onSearchManifest}
            hasActiveExternalFilters={false}
            setSearchExpanded={setSearchExpanded}
          />
          {cardConfigEnable && !searchExpanded && <ManifestCardConfiguration />}
          <ManifestMenu
            dispatch={dispatch}
            manifestSort={state.manifestSort}
            sortByColor={state.sortByColor}
            onViewChanged={onChangedView}
            selectedView={viewType}
          />
        </RightToolbar>
      </ToolbarContainer>
      {showWarningMessage && <ManifestWarningMessage content={renderContentMessage} />}
      <ManifestViewSelector
        sortByColor={state.sortByColor}
        isManifestDetailsDrawerOpen={state.manifestDetailsState.isDrawerOpen}
        selectedManifest={selectedManifest}
        disabledManifestIds={disabledManifestIds ?? []}
        data={manifestDataState.data ?? []}
        viewType={viewType}
        onManifestDetailsDrawerOpen={onManifestDetailsDrawerOpen}
      />
      <PagingMenuContainer>
        <MuiPagingMenu
          count={manifestDataState.total}
          page={manifestDataState.page}
          rowsPerPage={manifestDataState.pageSize}
          rowsPerPageOptions={manifestPageSizes}
          showFirstButton
          showLastButton
          labelDisplayedRows={handleRowLabels}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          size="small"
        />
      </PagingMenuContainer>
    </ManifestDriverViewsContainer>
  );
};

export default ManifestDriverPanel;
