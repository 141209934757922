class Constants {
  static readonly DISPATCH_GROUP_LIMIT = 1000;
  static readonly DRIVER_LOCATION_LIMIT = 2000;
  static readonly DISPATCH_STATION_LIMIT = 10000;
  static readonly DEFAULT_MANIFEST_PAGE_SIZE = 25;
  static readonly MANIFEST_PAGE_SIZE_OPTIONS = [25, 50, 75];
  static readonly MANIFEST_POLLING_INTERVAL = 15000;

  static readonly UNASSIGNED_JOB_STATUSES = ["N", "F", "I"];
  static readonly MAX_NUMBER_OF_CUSTOMERS_FILTERS = 10;

  static readonly JOBS_PANE_DEFAULT_SIZE = 800;
  static readonly MANIFEST_PANE_DEFAULT_SIZE = 370;
  static readonly RULE_REFRESH_INTERVAL = 5 * 60 * 1000;
  static readonly GOOGLE_API_KEY = "AIzaSyD6cEM1i3rSdP9CrHADl5giN8WOriKR13U";
  static readonly MANIFEST_NEW_AGE = 30 * 60;

  static readonly DEFAULT_MAX_CLOCK_SKEW_SECONDS = 600;
  static readonly CLOCK_SKEW_WARNING_THRESHOLD_MILLIS = Constants.DEFAULT_MAX_CLOCK_SKEW_SECONDS * 1000 * 0.8;

  static readonly NUMBER_OF_DETAILED_CARD_STOPS = 4;
  static readonly NUMBER_OF_COMPACT_CARD_STOPS = 3;

  static readonly MAX_HOUR_JOBS_FILTER = 99;
  static readonly MIN_HOUR_JOBS_FILTER = 0;

  static readonly MAX_NUMBER_OF_RETRY = 2;

  static readonly ARRIVAL_TIME_PRIOR_AT_LEAST = 1 * 60 * 1000;
  static readonly BROWSER_TIMEZONE_OFFSET_MINUTES = -new Date().getTimezoneOffset();

  static readonly MAX_UNREAD_MESSAGE_COUNT = 9;
  static readonly MANIFEST_DRAWER_RATIO_WIDTH = 60;
  static readonly GRAPHHOPPER_SOURCE = "dispatch";

  static readonly MeterToMileRatio = 0.000621371192;
}

enum UserPreferences {
  jobPanelSize = "dispatch.ui.assignment.jobPanelSize",
  jobPanelMinimized = "dispatch.ui.assignment.jobPanelMinimized",
  mapPanelSize = "dispatch.ui.assignment.mapPanelSize",
  mapPanelMinimized = "dispatch.ui.assignment.mapPanelMinimized",
  manifestViewType = "dispatch.ui.assignment.manifestViewType",
  manifestFilterHeader = "dispatch.ui.manifest.filter.header",
  manifestDateRangeHeader = "dispatch.ui.manifest.date.range.header",
  manifestSortingHeader = "dispatch.ui.manifest.sorting.header",
  manifestGroupHeader = "dispatch.ui.manifest.group.header",
  manifestRowPerPage = "dispatch.ui.manifest.row.per.page",
  jobGridColumnState = "dispatch.ui.jobs.grid.columnState",
  jobGridFilterState = "dispatch.ui.jobs.grid.filterState",
  jobGridFilterReadyDue = "dispatch.ui.jobs.grid.filterReadyDueState",
  jobGridFilterReadyDueCustomValue = "dispatch.ui.jobs.grid.filterReadyDueState.customValue",
  jobGridFilterPlaced = "dispatch.ui.jobs.grid.filterPlacedState",
  isTimeFormat24hr = "dispatch.ui.app.settings.isTimeFormat24hr",
  isPresentationMode = "dispatch.ui.app.settings.isPresentationMode",
  dispatchStationsGroup = "dispatch.ui.app.settings.dispatchStationsGroup",
  mapActionsBarButton = "dispatch.ui.app.actions.mapActionsBarButton",
  manifestDetailGridColumnState = "dispatch.ui.manifest.detail.grid.columnState",
  manifestDetailGridFilterState = "dispatch.ui.manifest.detail.grid.filterState",
  manifestCardConfigurations = "dispatch.ui.manifest.card.configurations",
  manifestCardSortByColor = "dispatch.ui.manifest.card.sortByColor",
  manifestMapActionBar = "dispatch.ui.manifest.map.actionBar"
}

enum TenantPreferences {
  driverRequirementEnforcement = "dispatch.ui.assignment.driverRequirementEnforcement",
  orderEntryType = "dispatch.ui.assignment.orderEntryType",
  serviceTypeColor = "dispatch.ui.jobs.serviceTypeColor",
  dispatcherIgnoreTimeWindows = "dispatch.ui.routing.dispatcherIgnoreTimeWindows",
  driverIgnoreTimeWindows = "dispatch.ui.routing.driverIgnoreTimeWindows",
  driverAverageDwellTime = "dispatch.ui.routing.driverAverageDwellTime",
  timezoneOfJobStop = "dispatch.ui.jobs.timezoneOfJobStop",
  requireArrAndDepTimes = "dispatch.ui.manifest.detail.requireArrAndDepTimes",
  unassignPartialJob = "dispatch.ui.manifest.detail.unassignPartialJob",
  vehicleIconSetup = "dispatch.ui.manifest.vehicleIconSetup",
  defaultManifestStatus = "dispatch.ui.manifest.defaultManifestStatus"
}

enum DateFormats {
  smallDate = "PP",
  longDate = "PPPP",
  monthDay = "LLL d",
  cellRendererDate = "EEE LLL dd yyyy",
  cellRendererTime = "h:mmaaa",
  osNow = "now",
  osISO = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
  localDate = "yyyy-MM-dd",
  localDateSlash = "MM/dd",
  awsDateTimeWithOffset = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
}

enum EmptyValueStrings {
  notAvailable = "N/A",
  notProvided = "Not Provided",
  unknown = "Unknown"
}

enum RouteOptimizationErrorCodes {
  TimeWindowError = 2,
  GroupRelationConstraint = 29,
  RelationContaint = 21
}

const START_OR_END_LOCATIONS_OPTIMIZATION_SERVICEID = ["start", "end"];
enum DwellTimeToolTip {
  INFORMATION_ICON = "Minimum value of 1 minute",
  WARNING = "Dwell time must be at least 1 minute"
}

const MIN_DWELL_TIME = 1;

const INVALID_FILE_NAME_REGEX = /[\\/:*?"<>|.]+/g;

enum OptimizedResultCords {
  OptimizedCords = "optimizedCords",
  OriginalCords = "originalCords",
  HomeCords = "homeCords"
}

const MESSAGE_ERROR_REGEX = /Cannot return null for non-nullable type/i;
enum ERR_WARNING_MESSAGES {
  BAD_DATA = "There is at least 1 manifest not showing because a stop from the manifest has an unsupported null data point"
}

enum MAP_OVERLAY_BREAK_POINTS {
  CHANGE_TO_COLUMN = "1305px",
  CHANGE_TO_SMALL_TEXT = "1200px"
}

enum ClockTimeFormat {
  TWELVE_HOUR = "12",
  TWENTY_FOUR_HOUR = "24"
}

const ClockTimeFormatLabels = {
  [ClockTimeFormat.TWELVE_HOUR]: "12 Hour Time",
  [ClockTimeFormat.TWENTY_FOUR_HOUR]: "24 Hour Time"
};

const ClockTimeToolTipContent =
  "When manifest cards are in the minimal or compact mode, the time will always be 24 hour, to save space. All other parts of the application will show respect a 12 hour selection.";

export {
  Constants,
  UserPreferences,
  TenantPreferences,
  DateFormats,
  EmptyValueStrings,
  RouteOptimizationErrorCodes,
  START_OR_END_LOCATIONS_OPTIMIZATION_SERVICEID,
  DwellTimeToolTip,
  MIN_DWELL_TIME,
  INVALID_FILE_NAME_REGEX,
  OptimizedResultCords,
  MESSAGE_ERROR_REGEX,
  ERR_WARNING_MESSAGES,
  MAP_OVERLAY_BREAK_POINTS,
  ClockTimeFormat,
  ClockTimeFormatLabels,
  ClockTimeToolTipContent
};
